.select {
  border: 2px solid #000;
  /* 2px wide solid black border */
  border-radius: 10px;
  /* Rounded corners */
  border-color: rgb(0, 181, 152);
}

.background1 {
  background-image: url('/public/imges/backlogo.png');
  margin: 0;
  padding: 0;
  height: 100vh;
  /* Use viewport height */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.backgroundTex {
  background-image: url('/public/imges/logoPhone.png');
  /* Use viewport height */
  background-size: auto;

  background-repeat: no-repeat;
}

.backColor {
  min-height: 100vh;
  background-color: #001c42;
}

/* Change image for large screens */
@media screen and (min-width: 1201px) {
  .background1 {
    background-image: url('/public/imges/pageRashi.jpg');
  }
}


@media screen and (min-width: 1201px) {
  .btnBig {
    font-size: xx-large;
  }
}

.animated-button {
  opacity: 0;
  position: relative;
  animation: appear 3s ease forwards, move 2s ease forwards;
  /* Define animations */
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes move {
  from {
    transform: translate(-50px);
    /* Move up by 50px */
  }

  to {
    transform: translateY(0);
    /* Move back to original position */
  }
}

.an {
  animation: appear 3s ease;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.anBack {
  animation: appearBack 1s ease forwards
}

@keyframes appearBack {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.dialog-container {
  position: relative;
}

.animated-b {
  opacity: 0;
  position: relative;
  animation: appear 2s ease forwards, moveUpDown 3s ease forwards;
  /* Define animations */
}

.state-back {
  z-index: 1;
  /* Ensure backdrop is behind the button */
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes moveUpDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}


.custom-image {
  position: absolute
    /* Add more styles as needed */
}

.back {
  border-radius: 2%;
  background-color: rgba(239, 236, 236, 0.797);
}

@media screen and (min-width: 1201px) {
  .back {
    height: fit-content;
    width: 60%;
    background-color: rgba(239, 236, 236, 0.797);
    border-radius: 1%;
  }
}

.background2 {
  background-color: rgb(237, 236, 236);
  min-height: 100vh;
  /* Replace with your desired color */
  /* Other background styles */
}

.centered-button1 {
  position: relative;
  top: 30%;
}

.centered-button {
  position: relative;
  top: 30%;
}


.buttonDialog {
  border: 3px solid rgb(0, 181, 152);
  /* 5px width black border */
  display: inline-block;
  padding: 8px;
  background-color: rgb(67, 66, 66);
  ;
  /* Button color */
  color: #fff;
  /* Text color */
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: large;
  font-weight: 500;
}


.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}



.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input[type="checkbox"] {
  /* Define the size of the checkbox */
  width: 28px;
  height: 28px;
}


.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
}


#NagishLiTrigger {
  right: 0 !important;
  left: 10px;
  bottom: 10px;
  filter: hue-rotate(300deg);
}

span.nagishli-trigger-icon {
  width: 40px;
  height: 40px;
  box-shadow: 0 0 0 2px #ffffff;
  border-radius: 30%;
  padding: 0px;
  padding-top: 1px;
}
