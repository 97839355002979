.charging-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vh; /* Adjust the height as needed */
}

.charging-circle {
    border: 4px solid #f3f3f3; /* Light grey border */
    border-top: 4px solid rgb(0, 28,66 ); /* Blue border on top */
    border-radius: 50%; /* Circular shape */
    width: 40px; /* Diameter of the circle */
    height: 40px; /* Diameter of the circle */
    animation: spin 1s linear infinite; /* Animation */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}