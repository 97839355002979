.title {
    text-align: center;
   
}

.background2 {
    background-color: rgb(237, 236, 236);

    /* Replace with your desired color */
    /* Other background styles */
  }
  .btn-cat {
    display: flex;
    justify-content: center;
    gap: 0.6rem
}

.hr {
    width: 50%;
    margin-Left: 25%;
    margin-Right: 25%;
    border-top: 3px solid;
    color: #0df6cf;
}

.hrBox {
    width: 100%;
    height: 0.25rem;
    color: rgb(36, 37, 37);
}
.hrBoxMrry {
    width: 40%;
    color: rgb(36, 37, 37);
}


.circleState {
    width: 20px;
    height: 20px;
    border-radius: 50%; /* Makes it a circle */
}

.custom-button {
    background-color: #79a8c7; /* Green */
    border: none;
    color: white;
    padding: 6px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 4px 1px;
    cursor: pointer;
    border-radius: 6px;
  }

  .custom-buttonSlect {
    background-color: #79a8c7; /* Green */
    border: none;
    color: white;
    padding: 6px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 4px 1px;
    cursor: pointer;
    border-radius: 6px;
  }

  .selcet-tepy{
    border: 2px solid #79a8c7; /* 2px wide solid black border */
    background-color: #79a8c7; /* Green */
    border-radius: 6px;
    color: rgb(0, 0, 0);
  }



.custom-buttonRemove {
    background-color: #f30c0c; /* Green */
    border: none;
    color: white;
    padding: 2px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 4px 1px;
    cursor: pointer;
    border-radius: 6px;
  }




  input[type="date"]::-webkit-datetime-edit, input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-clear-button {
    color: #fff;
    position: relative;
  }
  
  input[type="date"]::-webkit-datetime-edit-year-field{
    position: absolute !important;
    align-items: end;
    border-left:1px solid #8c8c8c;
    padding: 2px;
    color:#000;
  
  }
  
  input[type="date"]::-webkit-datetime-edit-month-field{
    position: absolute !important;
    border-left:1px solid #8c8c8c;
    padding: 2px;
    color:#000;
    right: 60px;
    align-items: end;
  }
  
  
  input[type="date"]::-webkit-datetime-edit-day-field{
    position: absolute !important;
    color:#000;
    padding: 2px;
    align-items: end;
    right: 90px;
  }

  .button-link {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    color: #0000ff; /* Color of the link text */
    /* Additional styles as needed */
  }

  .not-phone {
    display: none;
  }
  
  @media only screen and (min-width: 768px) {
    .not-phone {
      display: block;
    }
  }
